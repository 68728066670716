const validateReferencesInstallments = (references) => {
	if (
		!references ||
		typeof references !== "object" ||
		Array.isArray(references) ||
		!Object.prototype.hasOwnProperty.call(references, "installments")
	) {
		return false
	}
	return true
}

export const parseReferencesInstallments = (references) => {
	if (!validateReferencesInstallments(references)) return []
	const data = Object.entries(references.installments)
		.map(([id, value]) => ({
			id,
			name: value.title,
			ordering: value.ordering ?? Infinity,
		}))
		.sort((a, b) => a.ordering - b.ordering)
		.map(({ id, name }) => ({ id, name }))

	return data
}
